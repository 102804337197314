<template>
    <b-card no-body>
        <b-card-header>
            <strong>Workshop Capacity Report by {{tabs[activeTab]}}</strong>
        </b-card-header>
        <b-tabs card v-model="activeTab">
            <b-tab title="Group" lazy>
                <b-row>
                    <b-col>
                        <b-form-group size="sm" label="Allocation Year:">
                            <picker-of-dates type="year"
                                             v-model="groupAllocationYear"
                                             format="yyyy"
                                             placeholder="Select Allocation Year"
                                             clearble/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-right">
                        <b-button size="sm" variant="success" @click="exportGroupReport">Export Workshop Group Capacity Report</b-button>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Workshop" lazy>
                <b-row>
                    <b-col cols="md-3">
                        <b-form-group label="Scheduled Month/Year:">
                            <picker-of-dates type="month"
                                             v-model="workshopMonthYear"
                                             format="MMMM yyyy"
                                             placeholder="Scheduled Month/Year"
                                             clearable/>
                        </b-form-group>
                    </b-col>
                    <b-col cols="md-3">
                        <b-form-group label="Registrations Closing Date:">
                            <picker-of-dates v-model="workshopRegistrationsClosingDateRange"
                                             format="M/d/yyyy"
                                             placeholder="Scheduled Month/Year"
                                             clearable range/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="District(s):">
                            <b-input-group size="sm">
                                <b-select v-model="workshopDistricts" multiple :select-size="6" :options="districtOptions"/>
                                <b-input-group-addon>
                                    <b-button @click="workshopDistricts = []">
                                        <font-awesome-icon prefix="fas" icon="times"/>
                                    </b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Council(s):">
                            <b-input-group size="sm">
                                <b-select v-model="workshopCouncils" multiple :select-size="6" :options="councilOptions"/>
                                <b-input-group-addon>
                                    <b-button @click="workshopCouncils = []">
                                        <font-awesome-icon prefix="fas" icon="times"/>
                                    </b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Workshop(s):">
                            <b-input-group size="sm">
                                <b-select v-model="workshopWorkshops" multiple :select-size="6" :options="workshopOptions"/>
                                <b-input-group-addon>
                                    <b-button @click="workshopWorkshops = []">
                                        <font-awesome-icon prefix="fas" icon="times"/>
                                    </b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Session(s):">
                            <b-input-group size="sm">
                                <b-select v-model="workshopSessions" multiple :select-size="6" :options="sessionOptions"/>
                                <b-input-group-addon>
                                    <b-button @click="workshopSessions = []">
                                        <font-awesome-icon prefix="fas" icon="times"/>
                                    </b-button>
                                </b-input-group-addon>
                            </b-input-group>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="text-right">
                        <b-button-group size="sm">
                            <b-button variant="success" @click="exportWorkshopReport">Export Workshop Capacity Report</b-button>
                            <b-button variant="primary" @click="clearCriteria">Clear Search Criteria</b-button>
                        </b-button-group>
                    </b-col>
                </b-row>
            </b-tab>
        </b-tabs>
    </b-card>
</template>
<script>

import {Vue, Component, Watch} from 'vue-property-decorator';
import PickerOfDates from '@/components/shared/PickerOfDates';
import _ from 'underscore';
import {sprintf} from 'sprintf-js';
import {date, mkDate} from '@/util/formatters';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import download from '@/util/downloader';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

@Component({
    components: {
        PickerOfDates
    }
})
export default class WorkshopCapacityReport extends Vue {

    tabs = ['Group', 'Workshop'];
    activeTab = 0;

    // Group Criteria
    groupAllocationYear = null;

    // Workshop Criteria
    workshopMonthYear = null;
    workshopRegistrationsClosingDateRange = null;
    workshopDistricts = [];
    workshopCouncils = [];
    workshopWorkshops = [];
    workshopSessions = [];

    clearCriteria() {
        this.workshopMonthYear = null;
        this.workshopRegistrationsClosingDateRange = null;
        this.workshopDistricts = [];
        this.workshopCouncils = [];
        this.workshopWorkshops = [];
        this.workshopSessions = [];
    }

    get districtOptions() {
        return _.map(this.$store.getters['common/districts'], (d) => {
            return {
                value: d.id,
                text: d.name
            };
        });
    }

    get councilOptions() {
        const councils = _.groupBy(this.$store.getters['common/councils'], (c) => c.districtName);
        return _.map(councils, (cs, district) => {
            return {
                label: district,
                options: _.map(cs, (c) => {
                    return {
                        value: c.displayId,
                        text: sprintf('%s (%s)', c.name, c.displayId)
                    };
                })
            };
        });
    }

    get workshopOptions() {
        const workshops = _.chain(this.$store.getters['common/workshops'])
            .sortBy(ws => ws.value)
            .map(ws => {
                return {
                    value: ws.id,
                    text: ws.value
                };
            })
            .value();
        return workshops;
    }

    get sessionOptions() {
        const sessions = this.$store.getters['common/workshopSessionsWorkshopInstanceID'];
        const options = _.map(sessions, s => {
            return {
                text: s.value,
                value: s.id
            };
        });
        return options;
    }

    @Watch('workshopWorkshops')
    async workshopsSelected(workshopIds) {
        this.workshopSessions = [];
        await this.$store.dispatch('common/loadWorkshopSessionsWorkshopInstanceID', workshopIds)
            .catch(error => {
                this.$bvToast.toast(error.message, errorToastOptions);
            });
    }

    async exportGroupReport() {
        //BE expects date for API call
        // const allocationYear = this.groupAllocationYear.getFullYear();
        await this.doExport({
            type: 'GROUP',
            allocationYear: this.groupAllocationYear
        });
    }

    async exportWorkshopReport() {
        const criteria = {
            type: 'WORKSHOP',
            scheduledMonth: this.workshopMonthYear,
            registrationsClosing: this.workshopRegistrationsClosingDateRange,
            districtIds: this.workshopDistricts,
            councilIds: this.workshopCouncils,
            workshopIds: this.workshopWorkshops,
            sessionIds: this.workshopSessions
        };
        await this.doExport(criteria);
    }

    async doExport(criteria) {
        try {
            await download('POST', '/api/v1/report/workshop_capacity', JSON.stringify(criteria));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    destroyed() {
        //Clear any prior workshop sessions
        this.$store.dispatch('common/loadWorkshopSessionsWorkshopInstanceID', [])
            .catch(error => {
                this.$bvToast.toast(error.message, errorToastOptions);
            });
    }

    async mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Workshop Capacity Report', null, true));
        //Clear any prior workshop sessions
        this.$store.dispatch('common/loadWorkshopSessionsWorkshopInstanceID', [])
            .catch(error => {
                this.$bvToast.toast(error.message, errorToastOptions);
            });
        _.each([
            'common/loadDistricts',
            'common/loadCouncils',
            'common/loadWorkshops'
        ], (action) => {
            this.$store.dispatch(action)
                .catch(error => {
                    this.$bvToast.toast(error.message, errorToastOptions);
                });
        });
    }
}
</script>
<style scoped>

</style>